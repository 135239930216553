import { useStaticQuery, graphql } from "gatsby"

import { flattenNode } from "../../../services/arrays" 

export const useAirtableMedicineFormFields = () => {
  let formFields = useStaticQuery(graphql`
    query AirtableAddMedicineFormFields {
      allAirtableallFormFields(
        sort: { fields: data___Order }
        filter: { data: { Module: { eq: "Medicines" } } }
      ) {
        nodes {
          data {
            Name
            Label
            Options
            Placeholder
            Form_Field_Type
            Type
            Module
            Section
            Validation
            Required
            Order
            Field_Names
            Label
            Unit
            Is_Numeric
            Input_Mode
            Required
            Min
          }
        }
      }
    }
  `)

  return flattenNode(formFields?.allAirtableallFormFields)
}
