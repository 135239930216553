import { createValidationSchema } from "services/validations"

export const useAddOtherMedicineFormFields = ({ formFields }) => {
  let finalFormFields = formFields.filter(
    (field) =>
      field?.section === "Add Medicine" ||
      field?.section === "Add Other Medicine"
  )
  return {
    formFields: finalFormFields,
    validationSchema: createValidationSchema({ fields: finalFormFields }),
  }
}
