import React, { useContext, useState, Fragment } from "react"
import classNames from "classnames"
import { AppContext } from "context/AppContext"
import { Formik, Form } from "formik"

import Button from "elements/Button"
import ErrorMessage from "../../Elements/ErrorMessage"

import { generateFormField } from "elements/Form/services/form"
import { useAirtableMedicineFormFields } from "../utils/useAirtableMedicineFormFields"
import { useAddOtherMedicineFormFields } from "../hooks/useAddOtherMedicineFormFields"

const createProductTitle = (values) => {
  return (
    (values.drugName || values.drug) +
    " " +
    values.strength +
    " " +
    values.form.value +
    " " +
    "(" +
    values.brand +
    ")"
  )
}

const AddOtherMedicineModal = ({
  name,
  setListOfSelected,
  listOfSelected,
  setFieldValue,
}) => {
  const [loading, setLoading] = useState(false)
  const { dispatch } = useContext(AppContext)

  let medicineFormFields = useAirtableMedicineFormFields()

  const { formFields, validationSchema } = useAddOtherMedicineFormFields({
    formFields: medicineFormFields,
  })

  const handleCloseModal = () => {
    dispatch({ type: "HIDE_MODAL" })
  }

  const handleSubmit = (values) => {
    setLoading(true)

    const itemIndex = listOfSelected.findIndex(
      (medicine) => medicine.drugName === createProductTitle(values)
    )

    if (itemIndex !== -1) {
      setListOfSelected((prevList) => {
        const updatedItems = [...prevList]
        updatedItems[itemIndex].medicineQuantity += values.medicineQuantity
        return updatedItems
      })

      setFieldValue(name, [...listOfSelected])
    } else {
      setListOfSelected([
        ...listOfSelected,
        {
          productTitle: createProductTitle(values),
          drugName: createProductTitle(values),

          strength: values.strength,
          form: [values.form.value],
          brand: [values.brand],
          medicineQuantity: values.medicineQuantity,
          signa: values.sig,
          s2: values?.s2 === "Yes",
        },
      ])

      setFieldValue(name, [
        ...listOfSelected,
        {
          productTitle: createProductTitle(values),

          drugName: createProductTitle(values),

          strength: values.strength,
          form: [values.form.value],
          brand: [values.brand],
          medicineQuantity: values.medicineQuantity,
          signa: values.sig,
          s2: values?.s2 === "Yes",
        },
      ])
    }

    setLoading(false)

    handleCloseModal()
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        drugName: "",
        brand: "",
        form: { value: "", label: "" },
        strength: "",
        productQuantity: "",
        sig: "",
        s2: [],
      }}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue, submitCount, errors }) => (
        <Form>
          {formFields
            .sort(
              (firstFormField, secondFormField) =>
                firstFormField.order - secondFormField.order
            )
            .map((field) => (
              <Fragment>
                {generateFormField({
                  formFields: medicineFormFields,
                  formField: field,
                  values,
                  setFieldValue,
                  submitCount,
                  errors,
                })}
              </Fragment>
            ))}

          <ErrorMessage errors={errors} />

          <div className="isFullWidth is-flex is-justify-content-center columns">
            <Button
              type="button"
              color="transparent"
              disabled={loading}
              className={classNames(
                "mt-2 mb-3 column pb-0 pt-0 is-offset-1 is-2",
                { "is-loading": loading }
              )}
              onClick={() => handleCloseModal()}
            >
              Back
            </Button>
            <Button
              type="submit"
              color="secondary"
              disabled={loading}
              className={classNames(
                "mt-2 mb-3 column pb-0 pt-0 is-offset-1 is-2",
                { "is-loading": loading }
              )}
            >
              Submit
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default AddOtherMedicineModal
