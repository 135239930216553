import React, { Fragment, useState } from "react"
import classNames from "classnames"

import { Field, FieldArray, ErrorMessage } from "formik"
import { emptyFollowUpFieldsOnFieldRender } from "../../../services/general"
import { getFollowUpQuestionData, generateFormField } from "./services/form"
import { camelize } from "humps"

import styles from "../utils/elements.module.scss"

const FormStyledRadio = ({
  icon,
  name,
  value,
  label,
  icons,
  values,
  helper,
  onClick,
  options,
  children,
  isRequired,
  hideOptional,
  followUpQuestions = [],
  isFollowUpQuestion,
  setFieldValue,
  formFields,
  formValues,
  disabled,
  disabledOptions,
  onChange,
  showLabel,
}) => {
  const [currentFieldValue, setCurrentFieldValue] = useState(values?.[name])

  const handleSelectInput = (option) => {
    if (!disabledOptions?.includes(option) || !disabled) {
      setFieldValue(name, option)
      setCurrentFieldValue(option)
      if (onClick) onClick(option)

      followUpQuestions.forEach((followUpQuestion) => {
        const followUpField = getFollowUpQuestionData({
          followUpQuestion,
          formFields: formFields,
        })

        if (
          values?.[name] === currentFieldValue &&
          camelize(
            getFollowUpQuestionData({
              followUpQuestion,
              formFields: formFields,
            })?.referenceAnswer
          ) === camelize(currentFieldValue)
        )
          emptyFollowUpFieldsOnFieldRender(setFieldValue, followUpField, true)
        else
          emptyFollowUpFieldsOnFieldRender(setFieldValue, followUpField, false)
      })
    }
  }

  return (
    <Fragment>
      {showLabel && (
        <label
          className={classNames("label has-text-weight-normal has-text-left")}
        >
          {label}
          {isRequired && hideOptional && (
            <span className="has-text-grey is-italic"> (Optional)</span>
          )}
          {!!helper && (
            <span
              className={classNames(
                "help has-text-weight-normal has-text-left"
              )}
            >
              {helper}
            </span>
          )}
        </label>
      )}
      <FieldArray name={name}>
        {() => (
          <div className={classNames(styles["styledRadioFlex"])}>
            {options?.map((option, index) => (
              <div
                className={classNames(styles["styledRadioFlex__radio"])}
                key={index}
              >
                <Field>
                  {() => (
                    <div className={classNames("field")}>
                      <div
                        className={classNames(
                          styles[
                            `styledRadio__option${
                              values?.[name] === option ? "Active" : ""
                            }`
                          ],
                          styles[
                            disabledOptions?.includes(option)
                              ? "styledRadio__disabled"
                              : ""
                          ]
                        )}
                        onClick={() => {
                          if (!disabled) handleSelectInput(option)
                          // onChange(option)
                        }}
                        onKeyDown={() => {
                          if (!disabled) handleSelectInput(option)
                          // onChange(option)
                        }}
                        role="button"
                        tabIndex="0"
                      >
                        <div className="columns is-vcentered is-mobile ml-1 ml-0-mobile">
                          <div
                            className={classNames(
                              styles["styledRadio__radioButton"]
                            )}
                          >
                            {values?.[name] === option && (
                              <div
                                className={classNames(
                                  styles["styledRadio__radioButtonChecked"]
                                )}
                              ></div>
                            )}
                          </div>
                          <div className="column ml-1">
                            <div
                              className={classNames(
                                "is-flex pr-1 is-align-items-center is-flex-wrap mt-1-mobile",
                                styles["styledRadio__label"]
                              )}
                            >
                              {icons?.localFiles?.[index]?.childImageSharp
                                ?.fluid?.originalImg && (
                                <img
                                  width={50}
                                  alt="icon"
                                  src={
                                    icons?.localFiles?.[index]?.childImageSharp
                                      ?.fluid?.originalImg
                                  }
                                />
                              )}
                              <div className="column pl-1">
                                {option && (
                                  <p
                                    className={classNames(
                                      "is-size-5",
                                      styles[
                                        disabledOptions?.includes(option)
                                          ? "styledRadio__disabledOption"
                                          : ""
                                      ]
                                    )}
                                  >
                                    {option}
                                  </p>
                                )}
                                {children}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Field>
                <div
                  className={classNames(
                    { field: name === "sex" },
                    { "is-horizontal": name === "sex" }
                  )}
                >
                  {options.map((index) => (
                    <Fragment key={index} />
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </FieldArray>
      <div>
        {followUpQuestions?.length > 0 &&
          followUpQuestions.map((followUpQuestion) => {
            const followUpField = getFollowUpQuestionData({
              followUpQuestion,
              formFields: formFields,
            })
            if (
              values?.[name] === currentFieldValue &&
              camelize(followUpField?.referenceAnswer) ===
                camelize(currentFieldValue)
            ) {
              return generateFormField({
                formField: {
                  ...followUpField,
                  disabled: disabled,
                },
                formFields,
                values: formValues,
                setFieldValue,
              })
            }
            return null
          })}
      </div>

      <p className="help is-danger mt-0 mb-1">
        <ErrorMessage name={name} />
      </p>
    </Fragment>
  )
}

export default FormStyledRadio
