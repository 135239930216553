import React from "react"
import classNames from "classnames"

import Address from "../../Address"
import FormDate from "../FormDate"
import FormRadio from "../FormRadio"
import FormInput from "../FormInput"
import FormSelect from "../FormSelect"
import FormCheckbox from "../FormCheckbox"
import FormTextArea from "../FormTextArea"
import FormCalendar from "../FormCalendar"
import FormSchedule from "../FormSchedule"
import FormStyledRadio from "../FormStyledRadio"
import FormLikertRadio from "../FormLikertRadio"
import Search from "../../../Search/Search.js"
import FormUpload from "../FormUpload"

import { formatString, isHyperlink } from "services/general"

export const generateFormField = (config) => {
  let {
    formField,
    formFields,
    values,
    setFieldValue,
    onChange,
    errors,
    submitCount,
    type,
    dispatch,
    searchConfig,
    staticSpacing,
    staticData = {},
    context,
    showLabel,
  } = config
  switch (formField?.formFieldType?.[0]) {
    case "static":
      return (
        <div className={classNames("columns")}>
          <div
            className={classNames("column  has-text-weight-bold", {
              "is-6": !staticSpacing,
              "is-7 mr-2": staticSpacing,
            })}
          >
            {formField?.label}
          </div>
          <div
            className={classNames("column is-multiline", {
              "is-6": !staticSpacing,
              "is-5": staticSpacing,
            })}
          >
            {isHyperlink(formatString(staticData[formField?.name])) ? (
              <a
                href={formatString(staticData[formField?.name])}
                target="_blank"
                rel="noreferrer"
              >
                {formatString(staticData[formField?.name])}
              </a>
            ) : (
              formatString(staticData[formField?.name])
            )}
          </div>
        </div>
      )
    case "vitals":
      return (
        <FormInput
          type={
            formField?.formFieldType?.[0] === "text"
              ? "numeric"
              : formField?.formFieldType?.[0]
          }
          isFollowUpQuestion
          {...formField}
          required={values.waiveVitals.length}
          isDisabled={values.waiveVitals.length}
        />
      )
    case "text":
    case "tel":
    case "email":
    case "number":
    case "password":
      return (
        <FormInput
          type={
            formField?.formFieldType?.[0] === "text"
              ? "numeric"
              : formField?.formFieldType?.[0]
          }
          isFollowUpQuestion
          isDisabled={formField?.name === "email" && type === "edit"}
          {...formField}
        />
      )
    case "calendar":
      return (
        <FormCalendar
          {...formField}
          values={values}
          errors={errors}
          setFieldValue={setFieldValue}
        />
      )
    case "styledRadio":
      return (
        <FormStyledRadio
          {...formField}
          values={values}
          errors={errors}
          formFields={formFields}
          formValues={values}
          setFieldValue={setFieldValue}
          showLabel={showLabel}
        />
      )
    case "text area":
    case "textarea":
      return <FormTextArea isFollowUpQuestion {...formField} />
    case "date":
      return (
        <FormDate
          name={formField?.name}
          value={values[formField?.name]}
          formValues={values}
          setFieldValue={setFieldValue}
          formFields={formFields}
          onChange={onChange}
          config={formField?.dateConfig}
          {...formField}
        />
      )
    case "checkbox":
      return (
        <FormCheckbox
          values={values[formField?.name]}
          title={formField?.label}
          formFields={formFields}
          formValues={values}
          setFieldValue={setFieldValue}
          dispatch={dispatch}
          {...formField}
        />
      )
    case "radio":
      return (
        <FormRadio
          {...formField}
          value={values[formField?.name]}
          title={formField?.label}
          formFields={formFields}
          formValues={values}
          setFieldValue={setFieldValue}
          isRequired={!!formField?.required}
          disabled={formField?.isDisabled}
        />
      )
    case "select":
    case "multiselect":
      return (
        <FormSelect
          name={formField?.name}
          value={values[formField?.name]}
          formValues={values}
          setFieldValue={setFieldValue}
          formFields={formFields}
          isMulti={formField?.formFieldType?.[0] === "multiselect"}
          {...formField}
        />
      )
    case "address":
      return (
        <Address
          setFieldValue={setFieldValue}
          values={values}
          {...formField}
          isNationwide
          isRequired
        />
      )

    case "scale":
      return (
        <FormLikertRadio
          value={values[formField?.name]}
          title={formField?.label}
          formFields={formFields}
          formValues={values}
          setFieldValue={setFieldValue}
          {...formField}
        />
      )
    case "schedule":
      return (
        <FormSchedule
          formFields={formFields}
          formValues={values}
          setFieldValue={setFieldValue}
          otherSchedules={formField.otherScheduleNames.map(
            (otherSchedule) => values[otherSchedule]
          )}
          {...formField}
        />
      )

    case "upload":
      return (
        <FormUpload
          formFields={formFields}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          submitCount={submitCount}
          context={context}
          hideDropzone={
            values?.consultStatus === "Completed" && !values?.isDraft
          }
          {...formField}
        />
      )
    case "search":
      return (
        <Search
          values={values[formField?.name]}
          formValues={values}
          setFieldValue={setFieldValue}
          withIcon
          {...formField}
          {...searchConfig}
          formFieldName={formField?.name}
        />
      )
    default:
      return
  }
}

export const getFollowUpQuestionData = (config) => {
  let { followUpQuestion, formFields } = config
  let followUpData = formFields.filter((formField) => {
    return followUpQuestion === formField.name
  })

  if (followUpData.length > 0) return followUpData[0]
  else return {}
}
