import React, { useState, useContext } from "react"
import classNames from "classnames"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { AppContext } from "context/AppContext"
import ResultCard from "./ResultCard/ResultCard.js"
import SearchResultRow from "./SearchResultRow/SearchResultRow.js"

import styles from "./utils/search.module.scss"

const SearchResults = ({
  name,
  searchResults,
  isExactMatch,
  onSelect,
  setSearchResults,
  footer,
  setFieldValue,
  formFieldName,
  rowProperties,
  searchConfig,
  values,
  disabled,
}) => {
  const sortedSearchResults = searchResults.sort((a, b) => {
    return a.distance >= b.distance ? -1 : 1
  })
  const { dispatch } = useContext(AppContext)
  const [listOfSelected, setListOfSelected] = useState(values)
  const { cardProperties, onSelectCallback, onFooterSelect } = searchConfig
  // if (values) {
  //   if (Array.isArray(values)) setListOfSelected(values)
  //   else setListOfSelected ([values])
  // }

  return (
    <section className={classNames(styles["searchResult"])}>
      <ol className={classNames({ "is-hidden": !searchResults.length })}>
        {searchResults.length > 0 && !isExactMatch && (
          <li
            className={classNames(
              "has-text-primary has-text-weight-bold is-size-7"
            )}
          >
            <FontAwesomeIcon icon={faExclamationCircle} /> Here are the closest
            items we have to the search term you entered.
          </li>
        )}
        {sortedSearchResults.map((result) => (
          <SearchResultRow
            result={result}
            handleClick={() => {
              if (onSelectCallback) {
                onSelectCallback({
                  setFieldValue,
                  name,
                  setListOfSelected,
                  listOfSelected,
                  result,
                  dispatch,
                })
              } else {
                if (onSelect) onSelect(result)
                setListOfSelected([result])
              }

              setSearchResults([])
            }}
            content={rowProperties}
            formFieldName={formFieldName}
          />
        ))}

        <li className={classNames["patient"]}>
          <div className="columns">
            <p className="column is-flex">
              {footer?.left}
              <div
                href={footer?.link === "" ? "" : footer?.link}
                aria-hidden="true"
                role="button"
                className="mx-1"
                onClick={() => {
                  if (!!onFooterSelect) {
                    onFooterSelect({
                      name,
                      setListOfSelected,
                      listOfSelected,
                      setFieldValue,
                      dispatch,
                    })
                  }

                  setSearchResults([])
                }}
              >
                <span className="text-primary">{footer?.right}</span>
              </div>
            </p>
          </div>
        </li>
      </ol>

      {listOfSelected.length !== 0 &&
        listOfSelected.map((result, index) => (
          <ResultCard
            // key={result}
            index={index}
            name={name}
            cardProperties={cardProperties}
            cardData={result}
            handleRemoveItem={() => {
              listOfSelected.splice(index, 1)
              setFieldValue(name, [...listOfSelected])
            }}
            setFieldValue={setFieldValue}
            disabled={disabled}
          />
        ))}
    </section>
  )
}

export default SearchResults
