import React, { Fragment, useEffect, useState } from "react"
import classNames from "classnames"

import FormSelect from "./FormSelect.js"

import { setYears, setMonths, setDates } from "./services/date"

/**
 ** Two select fields (MM, DD) and text field (YYYY) with label and error message.
 ** Supported parameters:
 **
 ** fieldProps: {
 **               'name': "birth",
 **               'label': "Birth",
 **               'isRequired': true,
 **               'onChange': Function,
 **               ...props compatible in Field Component of Formik
 **             }
 **/
const FormDate = (fieldProps) => {
  const { month, date, year } = fieldProps.value
  // let config = {
  //   future: false,
  //   past: false,
  //   weekdays: false,
  //   birthday: true,
  //   // range: {
  //   //   minDate: "1900-01-01",
  //   //   maxDate: "2022-09-05",
  //   //   increment: "day",
  //   //   incrementValue: "1",
  //   // }
  //   minAge: 18
  // };
  var config = JSON.parse(fieldProps.config)

  // array of months
  const [months, setMonthOptions] = useState([])
  // array of dates
  const [dates, setDateOptions] = useState([])
  // array of years
  const [years, setYearOptions] = useState([])

  // set years on load
  //
  useEffect(() => {
    let yearArr = setYears(config).map(function (yearObj) {
      return yearObj.value
    })
    setYearOptions(yearArr)
    //eslint-disable-next-line
  }, [])

  // show different months if year changes
  const handleYearChange = ({ value }, setFieldValue) => {
    let year = value
    // set months based on year

    let monthArr = setMonths(config, year).map(function (monthObj) {
      return monthObj.value
    })

    setMonthOptions(monthArr)
    setFieldValue(`${fieldProps.name}.month`, { label: "", value: "" })
    setFieldValue(`${fieldProps.name}.date`, { label: "", value: "" })
  }

  // show different days if month changes
  const handleMonthChange = ({ value }, setFieldValue) => {
    let month = value
    let yearValue = year.value
    let dateArr = setDates(config, month, yearValue).map(function (dateObj) {
      return dateObj.value
    })
    setDateOptions(dateArr)
    setFieldValue(`${fieldProps.name}.date`, { label: "", value: "" })
  }
  return (
    <Fragment>
      <div className="columns is-mobile p-0 m-0">
        <div className="column p-0 m-0">
          <label
            className={classNames("label has-text-weight-normal", {
              "mb-0": !!fieldProps.helper,
            })}
          >
            {fieldProps.label}
            {!fieldProps.isRequired && (
              <span className="has-text-grey is-italic"> (Optional)</span>
            )}
          </label>
          {!!fieldProps.helper && (
            <span className="help" style={{ whiteSpace: "pre-wrap" }}>
              {fieldProps.helper}
            </span>
          )}
        </div>
      </div>

      <div className="columns is-mobile">
        <div className="column mb-0">
          <FormSelect
            {...fieldProps}
            label=""
            name={`${fieldProps.name}.year`}
            placeholder="YYYY"
            options={years}
            onChange={handleYearChange}
            value={year}
            isRequired={fieldProps.isRequired}
            hideOptional
          />
        </div>
        <div className="column mb-0">
          <FormSelect
            {...fieldProps}
            label=""
            name={`${fieldProps.name}.month`}
            placeholder="MMM"
            options={months}
            onChange={handleMonthChange}
            isDisabled={
              !!!fieldProps.value.year?.value ||
              fieldProps.disabled ||
              fieldProps.isDisabled
            }
            value={month}
            isRequired={fieldProps.isRequired}
            hideOptional
          />
        </div>
        <div className="column mb-0">
          <FormSelect
            {...fieldProps}
            label=""
            name={`${fieldProps.name}.date`}
            placeholder="DD"
            options={dates}
            isDisabled={
              !!!fieldProps.value.month?.value ||
              fieldProps.disabled ||
              fieldProps.isDisabled
            }
            value={date}
            isRequired={fieldProps.isRequired}
            hideOptional
          />
        </div>
      </div>
    </Fragment>
  )
}

export default FormDate
