import React from "react"
import { useMedicines } from "components/Consultations/hooks/useMedicines"
import { getSignedInUser } from "../../Auth/services/user.js"

import MedicineQuantityModal from "../MedicineQuantityModal/MedicineQuantityModal.js"
import AddOtherMedicineModal from "../AddOtherMedicineModal/AddOtherMedicineModal"

const GetSearchConfig = ({ formFieldName }) => {
  let allMedicines = useMedicines()
  let { patients, permission } = getSignedInUser()

  var medicineData = []

  for (const i in permission?.consultTypes) {
    medicineData = medicineData.concat(
      allMedicines.filter(
        (medicine) => medicine?.programCode === permission?.consultTypes[i]
      )
    )
  }

  // if (
  //   permission?.consultTypes.includes("MGX1SOLMH") &&
  //   userData?.role !== GATSBY_MHP_ROLE_ID
  // ) {
  //   medicineData = medicineData?.filter((medicine) => {
  //     return medicine?.s2 === null
  //   })
  // }

  const openMedicineQuantityModal = ({
    setFieldValue,
    name,
    setListOfSelected,
    listOfSelected,
    result,
    dispatch,
  }) => {
    dispatch({
      type: "SHOW_MODAL",
      payload: {
        heading: "Add Medicine",
        isCard: true,
        headerClass: `has-text-info has-background-info-light has-text-weight-bold is-size-2`,
        content: (
          <MedicineQuantityModal
            setListOfSelected={setListOfSelected}
            listOfSelected={listOfSelected}
            result={result}
            setFieldValue={setFieldValue}
            name={name}
          />
        ),
      },
    })
  }

  const openAddOtherMedicineModal = ({
    name,
    setFieldValue,
    setListOfSelected,
    listOfSelected,
    dispatch,
  }) => {
    dispatch({
      type: "SHOW_MODAL",
      payload: {
        heading: "Add Other Medicine",
        isCard: true,
        headerClass: `has-text-info has-background-info-light has-text-weight-bold is-size-2`,
        content: (
          <AddOtherMedicineModal
            name={name}
            setFieldValue={setFieldValue}
            setListOfSelected={setListOfSelected}
            listOfSelected={listOfSelected}
          />
        ),
      },
    })
  }
  switch (formFieldName) {
    case "patient":
      return {
        isMultipleSearch: false,
        dataSource: {
          type: "json",
          lookupKeys: ["firstName", "lastName", "employeeNumber"],
          data: patients,
        },
        resultRowProperties: [
          { employeeNumber: "Employee Number" },
          { department: "Department" },
          { mobileNumber: "Mobile Number" },
        ],
        cardProperties: [
          [{ age: "Age: " }, { lastClinicVisit: "Last Clinic Visit: " }],
          [
            { previousComplaint: "Previous Complaint: " },
            { allergies: "Allergies: " },
          ],
        ],
        footer: {
          left: "Can't find the employee?",
          right: " Add new employee",
          link: "/patients/add",
        },
      }

    case "medicine":
      return {
        isMultipleSearch: true,
        dataSource: {
          type: "json",
          lookupKeys: ["productTitle", "molecule"],
          data: medicineData,
        },
        resultRowProperties: [],
        cardProperties: [[{ qty: "Qty: " }, { signa: "Signa: " }]],
        footer: {
          left: "Not in the list?  ",
          right: " Add medicine",
          link: "",
        },
        onSelectCallback: openMedicineQuantityModal,
        onFooterSelect: openAddOtherMedicineModal,
      }
    default:
      return []
  }
}
export default GetSearchConfig
