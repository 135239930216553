import React, { useState, useEffect, Fragment } from "react"
import classNames from "classnames"
import { ErrorMessage } from "formik"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import SearchResults from "./SearchResults"
import Message from "../Elements/Message"

import { fuzzySearch } from "./services/fuzzySearch"

import { isObjectEmpty } from "services/general"

import getSearchConfig from "./utils/getSearchConfig.js"

const Search = ({
  label,
  isRequired,
  formFieldType,
  name,
  placeholder,
  isMobile,
  message,
  values,
  formFieldName,
  setFieldValue,
  disabled,
  isDisabled
}) => {
  const [searchQuery, setSearchQuery] = useState("")
  const [searchQueue, setSearchQueue] = useState(null)
  const [searchResults, setSearchResults] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const {
    dataSource,
    cardProperties,
    isMultipleSearch,
    resultRowProperties,
    footer,
  } = getSearchConfig({ formFieldName: name })

  useEffect(() => {
    if (searchQuery) {
      setIsLoading(true)
      switch (dataSource.type) {
        case "api":
          break
        case "graphql":
          if (searchQueue) clearTimeout(searchQueue)
          setSearchQueue(
            setTimeout(async () => {
              await setSearchResults(
                fuzzySearch(searchQuery, dataSource.data, dataSource.lookupKeys)
              )
              setIsLoading(false)
            }, 1000)
          )
          break
        case "json":
          if (searchQueue) clearTimeout(searchQueue)

          setSearchQueue(
            setTimeout(async () => {
              let searchResults = await fuzzySearch(
                searchQuery,
                dataSource.data,
                dataSource.lookupKeys
              )
              setSearchResults(searchResults)
              setIsLoading(false)
            }, 1000)
          )
          break
        default:
          break
      }
    }
    //eslint-disable-next-line
  }, [searchQuery])

  const handleValidateMatchDistance = () => {
    let resultValues = searchResults[0]?.values
    let distance = Array.isArray(resultValues)
      ? resultValues[0]?.distance
      : resultValues?.distance
    return searchResults.length > 0 && distance !== 1
  }

  const handleOnSelect = async (result) => {
    setFieldValue(name, [...values, result])
  }

  return (
    <Fragment>
      <div className={classNames("field")}>
        {!!label && (
          <label
            className={classNames("label", {
              "has-text-weight-normal has-text-grey-dark": !isRequired,
            })}
          >
            {label}
          </label>
        )}
        <div className="field-body">
          <div className="field">
            {/* isObjectEmpty(values) && (name==="patient" || name === "medicine") && */}
            {isMultipleSearch ||
            (isObjectEmpty(values) && !isMultipleSearch) ? (
              <p
                className={classNames("control has-icons-left", {
                  "is-loading": isLoading,
                })}
              >
                <input
                  type={formFieldType}
                  className="input"
                  name={name}
                  placeholder={placeholder}
                  onChange={(event) => {
                    setSearchQuery(event.target.value)
                  }}
                  disabled = {disabled || isDisabled}
                  readOnly={isMobile}
                />

                <span className={classNames("icon is-small is-left")}>
                  {/* {loading ? (
            <FontAwesomeIcon icon={faSpinner} spin={true} />
          ) : ( */}
                  <FontAwesomeIcon
                    className={classNames(["searchIcon"])}
                    icon={faSearch}
                  />
                  {/* )} */}
                </span>
              </p>
            ) : null}

            <SearchResults
              name={name}
              values={values}
              searchResults={searchResults.slice(0, 50)}
              isExactMatch={handleValidateMatchDistance()}
              setSearchResults={setSearchResults}
              setSearchQuery={setSearchQuery}
              onSelect={handleOnSelect}
              cardProperties={cardProperties}
              rowProperties={resultRowProperties}
              formFieldName={formFieldName}
              setFieldValue={setFieldValue}
              isMultipleSearch={isMultipleSearch}
              searchConfig={getSearchConfig({ formFieldName: name })}
              footer={footer}
              disabled = {disabled || isDisabled}
            />
          </div>
        </div>
      </div>
      {!!message?.content ? (
        <Message color={message?.color} className="mt-1">
          {message?.content}
        </Message>
      ) : null}

      <p className="help mt-0 mb-1 is-danger has-text-left">
        <ErrorMessage name={name} />
      </p>
    </Fragment>
  )
}

export default Search
