import { createValidationSchema } from "services/validations"

export const useAddMedicineFormFields = ({ formFields }) => {
  let finalFormFields = formFields.filter(
    (field) => field?.section === "Add Medicine"
  )
  return {
    formFields: finalFormFields,
    addMedicineValidationSchema: createValidationSchema({ fields: finalFormFields }),
  }
}
