import { useStaticQuery, graphql } from "gatsby"
import { flattenNode } from "services/arrays"

const ALL_MEDICINE = graphql`
  query AirtableMedicineFormFields {
    allAirtableallMedicine(filter: { data: { ShowOnWebsite: { eq: true } } }) {
      nodes {
        data {
          Strength
          ShowOnWebsite
          RecordID
          ProductTitle
          Product
          Molecule
          ItemCode
          Form
          DrugName
          Classification
          Brand
          S2
          Program_Code
        }
      }
    }
  }
`

export const useMedicines = () => {
  var data = useStaticQuery(ALL_MEDICINE)
  return flattenNode(data.allAirtableallMedicine)
}
