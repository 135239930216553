import React from "react"
import classNames from "classnames"

import styles from "../utils/search.module.scss"
import getTitle from "../services/getTitle"

import { renderProperty } from "./utils/renderProperty"

const ResultCard = ({
  name,
  cardProperties,
  cardData,
  handleRemoveItem,
  index,
  disabled,
}) => {
  const handleRemove = () => {
    handleRemoveItem()
  }

  return (
    <div
      id={`${name}-${index}`}
      role="contentinfo"
      className={classNames(
        "columns is-flex is-flex-direction-row mb-4",
        styles["employeeResultCard"]
      )}
    >
      <div className="column is-11 is-flex is-flex-direction-column">
        <div className="isFullWidth">
          <p className="has-text-weight-bold pb-1">
            {getTitle(cardData, name)}
          </p>
        </div>
        <div className="">
          <div className="pb-1">
            {cardProperties?.map((property, index) => (
              <div className="columns">
                {property?.map((subProperty, index) => (
                  <div
                    className={classNames(
                      styles["smallColumn"],
                      "column is-6 is-flex flex-row"
                    )}
                    key={index}
                  >
                    <p className={classNames("has-text-grey")}>
                      {Object.values(subProperty)[0]}
                    </p>
                    &nbsp;
                    <p>{renderProperty(cardData, subProperty)}</p>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="column is-1">
        {!!!disabled && (
          <button
            className={classNames("delete", styles["close"])}
            type="button"
            aria-label="Delete Button"
            onClick={handleRemove}
          />
        )}
      </div>
    </div>
  )
}

export default ResultCard
