import React, { Fragment } from "react"
import classNames from "classnames"

import UploadDocumentDropzone from "elements/UploadDocumentDropzone"

const FormUpload = (fieldProps) => {
  const {
    values,
    setFieldValue,
    errors,
    submitCount,
    label,
    context,
    isRequired,
    hideDropzone
  } = fieldProps
  
  return (
    <Fragment>
      <label className={classNames("label has-text-weight-normal")}>
        {/* {label} */}
        {!isRequired && (
          <span className="is-italic has-text-grey">&nbsp;(Optional)</span>
        )}
        {!!fieldProps?.helper && (
          <span
            className={classNames(
              "help has-text-weight-normal has-text-grey",
              fieldProps?.helperClassName
            )}
          >
            {fieldProps?.helper}
          </span>
        )}
      </label>
      <div className="columns">
        <div className="column" id={`${fieldProps?.name}`}>
          <UploadDocumentDropzone
            {...fieldProps}
            docType={`${fieldProps?.name}`}
            label={label}
            icon="id"
            maxFiles={fieldProps?.maxFiles || 5}
            name={`${fieldProps?.name}`}
            value={values?.[fieldProps?.name]}
            setFieldValue={setFieldValue}
            errors={errors}
            submitCount={submitCount}
            context={context}
            hideDropzone={hideDropzone}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default FormUpload
