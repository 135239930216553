import {
  renderStringValue,
  calculateBirthday,
  getLatestConsultation,
  isObjectEmpty,
} from "../../../../services/general"

import { getSignedInUser } from "../../../Auth/services/user.js"

export const renderProperty = (cardData, subProperty) => {
  const { consultations } = getSignedInUser()
  let patientConsultations = consultations?.filter((consultation) => {
    return consultation.email === cardData.email
  })
  let latestConsultation = {}
  if (!patientConsultations) {
    latestConsultation = getLatestConsultation({
      patientConsultations,
      userData: cardData,
    })
  }
  if (Object.keys(subProperty).includes("age")) {
    return calculateBirthday({ birthday: cardData["birthday"] })
  } else if (Object.keys(subProperty).includes("lastClinicVisit")) {
    return !isObjectEmpty(latestConsultation.consultationDateTime)
      ? new Date(
          latestConsultation.consultationDateTime?.seconds * 1000
        ).toLocaleString()
      : "N/A"
  } else if (Object.keys(subProperty).includes("previousComplaint")) {
    return latestConsultation.chiefComplaint
      ? latestConsultation.chiefComplaint
      : "N/A"
  } else if (Object.keys(subProperty).includes("allergies")) {
    return latestConsultation.allergies ? latestConsultation.allergies : "N/A"
  } else if (Object.keys(subProperty).includes("qty")) {
    return cardData.medicineQuantity || cardData.qty || "N/A"
  } else if (cardData[Object.keys(subProperty)[0]] !== undefined) {
    return renderStringValue({ value: cardData[Object.keys(subProperty)[0]] })
  } else {
    return "N/A"
  }
}
