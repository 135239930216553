import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"

import styles from "./utils/elements.module.scss"
import { isObjectEmpty } from "services/general"
/**
 *
 * @param {string} color options: primary, success, info, danger, light, dark, default: warning
 * @param {number} size max: 7, default: 6
 * @param {string} align options: centered, left, right
 * @param {jsx, string} message
 */

const ErrorMessage = ({ color, size, message, align, errors }) => {
  if (!isObjectEmpty(errors)) {
    return (
      <div className={classNames(`message is-${color || "danger"}`)}>
        <div
          className={classNames(
            `message-body content is-size-${size || 6} has-text-${
              align || "left"
            }`,
            styles["message"]
          )}
        >
          <p>
            {" "}
            {message || (
              <span>
                You may have missed some required fields. Please scan through
                the form and check if your information is complete:
              </span>
            )}
          </p>

          <p>
            <ul>
              {Object.keys(errors).map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </p>
        </div>
      </div>
    )
  }
  return null
}

ErrorMessage.propTypes = {
  message: PropTypes.node.isRequired,
  color: PropTypes.string,
  size: PropTypes.number,
}

export default ErrorMessage
