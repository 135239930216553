import React from "react"
import classNames from "classnames"

import styles from "../utils/searchRow.module.scss"

const SearchResultRow = ({ result, handleClick, content, formFieldName }) => {
  return (
    <li>
      <button
        onClick={() => handleClick()}
        onKeyDown={() => handleClick()}
        className={classNames(styles["resultRow"])}
        isFullWidth
      >
        <div className="is-flex has-text-weight-bold">
          {formFieldName === "patient" ? (
            <p>
              {result?.lastName}, {result?.firstName}
            </p>
          ) : (
            <p>{result?.drugName || result?.drug}</p>
          )}
        </div>
        <div
          className={classNames(
            "is-flex is-justify-content-space-between is-size-7 mt-1",
            styles["employeeResult"]
          )}
        >
          {content.map((data) => (
            <div
              className={classNames(
                "is-flex  has-text-left",
                {
                  "is-flex-direction-column": Object.keys(content).length > 1,
                },
                {
                  "is-flex-direction-row": Object.keys(content).length === 1,
                }
              )}
            >
              <p className="has-text-weight-bold has-text-gray ">
                {Object.values(data)[0]}&nbsp;
              </p>
              <p className="has-text-weight-normal">
                {result[Object.keys(data)[0]]}
              </p>
            </div>
          ))}
        </div>
      </button>
    </li>
  )
}

export default SearchResultRow
