export const getTitle = (result, formFieldName) => {
  switch (formFieldName) {
    case "patient":
      return result.lastName + ", " + result.firstName
    case "medicine":
      return result.productTitle ||  result.drugName || result.drug
    default:
      break
  }
}

export default getTitle
